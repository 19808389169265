var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AddItemComponent',{attrs:{"title":"販売登録","confirmSubmitForm":_vm.confirmSubmitForm,"goBack":function () { return _vm.$router.back(); },"onDecode":_vm.onDecode,"onRegisterItem":_vm.onRegisterItem},scopedSlots:_vm._u([{key:"dataTable",fn:function(){return [_c('CDataTable',{attrs:{"hover":"","striped":"","border":"","itemsPerPage":200,"items":_vm.itemMasters,"fields":_vm.fields},scopedSlots:_vm._u([{key:"price",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CInput',{staticStyle:{"min-width":"110px"},attrs:{"id":"price","label":"","horizontal":"","type":"number","min":"0"},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})],1)}},{key:"pp_bs_code",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_c('CSelect',{staticStyle:{"min-width":"100px"},attrs:{"id":"ppbs","label":"","horizontal":"","options":_vm.ppbsOptions},on:{"change":function (e) { return _vm.selectPpbs(e, index); }},model:{value:(item.pp_bs_code),callback:function ($$v) {_vm.$set(item, "pp_bs_code", $$v)},expression:"item.pp_bs_code"}})],1)}},{key:"is_special",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_c('CSelect',{staticStyle:{"min-width":"130px"},attrs:{"id":"special","label":"","horizontal":"","options":_vm.specialOptions},on:{"change":function (e) { return _vm.selectSpecial(e, index); }},model:{value:(item.is_special),callback:function ($$v) {_vm.$set(item, "is_special", $$v)},expression:"item.is_special"}})],1)}},{key:"sales_user_id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_c('CSelect',{staticStyle:{"min-width":"130px"},attrs:{"id":"salesuser","label":"","horizontal":"","options":_vm.userSales},on:{"change":function (e) { return _vm.selectChangeUserSales(e, index); }},model:{value:(item.sales_user_id),callback:function ($$v) {_vm.$set(item, "sales_user_id", $$v)},expression:"item.sales_user_id"}})],1)}},{key:"sold_at",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CInput',{attrs:{"id":"soldat","label":"","horizontal":"","type":"date"},model:{value:(item.sold_at),callback:function ($$v) {_vm.$set(item, "sold_at", $$v)},expression:"item.sold_at"}})],1)}},{key:"delete",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CButtonClose',{on:{"click":function($event){return _vm.handleDelete(item)}}})],1)}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }