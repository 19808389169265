<template>
  <AddItemComponent
    title="販売登録"
    :confirmSubmitForm="confirmSubmitForm"
    :goBack="() => $router.back()"
    :onDecode="onDecode"
    :onRegisterItem="onRegisterItem"
  >
    <template v-slot:dataTable>
      <CDataTable
        hover
        striped
        border
        :itemsPerPage="200"
        :items="itemMasters"
        :fields="fields"
      >
        <td slot="price" slot-scope="{ item }">
          <CInput
            id="price"
            label=""
            horizontal
            style="min-width : 110px"
            type="number"
            min="0"
            v-model="item.price"
          />
        </td>
        <td slot="pp_bs_code" slot-scope="{ item, index }">
          <CSelect
            id="ppbs"
            label=""
            horizontal
            style="min-width : 100px"
            v-model="item.pp_bs_code"
            :options="ppbsOptions"
            @change="(e) => selectPpbs(e, index)"
          />
        </td>
        <td slot="is_special" slot-scope="{ item, index }">
          <CSelect
            id="special"
            label=""
            horizontal
            style="min-width : 130px"
            v-model="item.is_special"
            :options="specialOptions"
            @change="(e) => selectSpecial(e, index)"
          />
        </td>
        <td slot="sales_user_id" slot-scope="{ item, index }">
          <CSelect
            id="salesuser"
            label=""
            horizontal
            style="min-width : 130px"
            v-model="item.sales_user_id"
            :options="userSales"
            @change="(e) => selectChangeUserSales(e, index)"
          />
        </td>
        <td slot="sold_at" slot-scope="{ item }">
          <CInput id="soldat" label="" horizontal type="date" v-model="item.sold_at" />
        </td>
        <td slot="delete" slot-scope="{ item }">
          <CButtonClose @click="handleDelete(item)" />
        </td>
      </CDataTable>
    </template>
  </AddItemComponent>
</template>

<script>
import apiStock from "../../api/stockApi";
import { SCREEN_KEY, STATUS_ITEM } from "@/utils/constance";
import salesHistory from "@/api/salesHistoryApi";
import ModalComponent from "@/components/views/ModalComponent";
import AddItemComponent from "@/components/views/AddItemComponent";
import userApi from "@/api/userApi";
import Vue from "vue";

export default {
  components: { ModalComponent, AddItemComponent },
  data() {
    return {
      itemMasters: [],
      itemIds: [],
      userSales: [],
      fields: [
        { key: "item_id", label: "商品ID" },
        { key: "item_code", label: "品番" },
        { key: "name", label: "商品名" },
        { key: "size", label: "サイズ" },
        { key: "color", label: "色" },
        { key: "price", label: "販売価格" },
        { key: "pp_bs_code", label: "PP/BS" },
        { key: "is_special", label: "優待" },
        { key: "sales_user_id", label: "販売担当者" },
        { key: "sold_at", label: "販売日" },
        { key: "delete", label: "削除" },
      ],
      userIdDefault: "",
      ppbsOptions: [
        { label: "PP", value: "PP" },
        { label: "BS", value: "BS" },
      ],
      specialOptions: [
        { label: "非優待", value: 0 },
        { label: "優待", value: 1 },
      ],
    };
  },

  created() {
    this.getUserShop();
    let user = this.$store.getters.user;
    this.userIdDefault = user.id;
  },

  methods: {
    async onDecode(item_id) {
      return await apiStock
        .itemScanQR({
          item_id,
          screen_key: SCREEN_KEY.ITEM_SCAN_QR_CODE_FOR_SOLD,
        })
        .then((response) => {
          let data = response.data;
          if (!data) {
            return {
              message: "スキャンした商品は存在しません",
              type: "error",
            };
          }

          if (data.status === STATUS_ITEM["SOLD"]) {
            return {
              message: "販売済みの商品です",
              type: "error",
            };
          }

          if (this.containsObject(data)) {
            return {
              message: "テーブルに商品が登録済みです",
              type: "error",
            };
          }

          this.addItemToTable(data);
          this.itemIds.push(item_id);
          return {
            message: data.item.id + " : " + data.item.item_master.name + "をテーブルに追加します",
            type: "success",
          };
        })
        .catch(() => {
          return {
            message: "商品が在庫にありません。",
            type: "error",
          };
        });
    },

    confirmSubmitForm() {
      this.$confirm({
        title: "確認",
        message: "販売済として登録します。宜しいですか？",
        button: {
          no: "いいえ",
          yes: "はい",
        },
        callback: (confirm) => {
          if (confirm) {
            // ... do something
            this.storeSalesHistory();
          }
        },
      });
    },

    storeSalesHistory() {
      this.loading = true;
      if (!this.itemMasters.length) {
        Vue.$toast.error("少なくとも1つの製品が必要です");
        return;
      }
      let params = {
        sales_histories: this.itemMasters,
        screen_key: SCREEN_KEY.ITEM_SCAN_QR_CODE,
      };
      salesHistory
        .storeSalesByScan(params)
        .then(() => {
          Vue.$toast.success("検品が完了しました。");
          setTimeout(this.$router.back(), 1000);
        })
        .catch(() => {
          Vue.$toast.error("入庫が完了していない商品は販売出来ません");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    selectChangeUserSales(val, key) {
      this.itemMasters[key].sales_user_id = parseInt(val.target.value);
    },

    selectPpbs(val, key) {
      this.itemMasters[key].pp_bs_code = val.target.value;
    },

    selectSpecial(val, key) {
      this.itemMasters[key].is_special = val.target.value;
    },

    handleDelete(item) {
      this.itemMasters = this.itemMasters.filter((i) => i.id !== item.id);
    },

    addItemToTable(data) {
      let item = {
        id: data.id,
        item_id: data.item_id,
        item_code: data.item.item_master.item_code,
        name: data.item.item_master.name,
        size: data.item.item_master.size.name,
        color: data.item.item_master.color.color_code + ':' + data.item.item_master.color.name,
        price: data.price ? data.price : data.item.item_master.price,
        sold_at: new Date().toISOString().slice(0, 10),
        sales_user_id: this.userIdDefault,
        pp_bs_code: data.pp_bs_code ? data.pp_bs_code : data.item.item_master.pp_bs_code,
        is_special: data.is_special,
      };

      this.itemMasters.push(item);
    },

    onRegisterItem(item_id) {
      apiStock
        .itemScanQR({
          item_id,
          screen_key: SCREEN_KEY.ITEM_SCAN_QR_CODE_FOR_SOLD,
        })
        .then((response) => {
          let data = response.data;
          if (!data) {
            Vue.$toast.error("スキャンした商品は存在しません");
            return;
          }

          if (data.status === STATUS_ITEM["SOLD"]) {
            Vue.$toast.error("販売済みの商品です");
            return;
          }

          if (this.containsObject(data)) {
            Vue.$toast.error("リストに登録済みです");
            return;
          }

          this.addItemToTable(data);
          this.itemIds.push(item_id);
        })
        .catch(() => {
          Vue.$toast.error("商品が在庫にありません。");
        });
    },

    containsObject(obj) {
      let x = false;

      this.itemMasters.map((i) => {
        if (i.id === obj.id) {
          x = true;
        }
      });

      return x;
    },

    getUserShop() {
      this.loading = true;
      userApi
        .getUserShop()
        .then((response) => {
          this.userSales = response.data;
          this.userSales.forEach((user) => {
            user.label = user.name;
            user.value = user.id;
          });
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
